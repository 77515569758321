import React from 'react'

export default function Contact() {
    return <div className="main-text">
        <h1>
            Get in touch.
        </h1>
        <p>
            Let's talk via email at hello@cameocodes.com
        </p>
    </div>

}