import React from 'react'

export default function Home() {
    return <div className="main-text">
        <h1>
            Hello!<br/>
            My name is Cameo<br/>
            I'm a developer
        </h1>
        <p>A Melbournian with a love of all things web development.</p>
        <p>And also dogs.</p>
    </div>

}